/******************************************************************************
 * Content
 ******************************************************************************/

 body {
   padding-bottom: 70px;
   color: var(--global-text-color);
   background-color: var(--global-bg-color);
 }

 body.fixed-top-nav {
   // Add some padding for the nav-bar.
   padding-top: 56px;
 }

 body.sticky-bottom-footer {
   // Remove padding below footer.
   padding-bottom: 0;
 }

.container {
  max-width: $max-content-width;
}

// Profile
.profile {
  img {
    width: 100%;
  }
}

// TODO: redefine content layout.

.me-title {
  font-weight: bold;
  font-size: larger;
}


.me-info {
  width: 70%;  
  padding: 10pt;


  .me-contacts {
    padding-left: 0pt;
    padding-top: 30pt;
    list-style: none;
  }
}

.important {
  border: 1px solid;
  padding: 10px;
  box-shadow:3px 3px 3px grey;
}

/******************************************************************************
 * Publications
 ******************************************************************************/

// TODO: redefine publications layout.


/*****************************************************************************
* Projects
*****************************************************************************/

// TODO: redefine projects layout.
